import Header from "./layout/header";
import Footer from "./layout/footer";
import Theme from "../styles/theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import React from "react";
import { Helmet } from "react-helmet";
import { Grid, Container, makeStyles, ThemeProvider } from "@material-ui/core";

const UseStyles = makeStyles({
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },

  content: {
    flex: "1 0 auto",
  },

  brands: {
    marginTop: "3.5rem",
  },
});

type LayoutProps = { currentPage?: string; children?: unknown };

/*
 * currentPage -> The page being viewed
 * children -> HTML component hierarchy
 */
const Layout = (props: LayoutProps): JSX.Element => {
  const Classes = UseStyles();

  return (
    <>
      <Helmet>
        {/* Global site tag (gtag.js) - Google Analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-29728294-11"
        />
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-29728294-11');
          `}
        </script>
        <title>Awesome Promos</title>
      </Helmet>
      <ThemeProvider theme={Theme}>
        <CssBaseline />
        <div className={Classes.contentWrapper}>
          <Header currentPage={props.currentPage} />
          <Container maxWidth="lg" className={Classes.content}>
            <Grid container spacing={2}>
              {props.children}
            </Grid>
          </Container>
          <Footer />
        </div>
      </ThemeProvider>
    </>
  );
};

export default Layout;
