import { GQLImage } from "../types";

const GlobalConstants = {
  Home: "Home",
  Content: "Content",
  Description: "Description",
  Title: "Title",
  Properties: "Properties",
  Name: "Name",
  Brand: "Brand",
  Brief: "Brief",
  Variations: "Variations",
  Information: "Information",
  Images: "Images",
  DecorLocImages: "Decoration Location Images",
  Price: "Price",
  Sku: "SKU",
  Popularity: "Popularity",

  // 404.tsx
  PageNotFound: "Page Not Found",
  AccessDenied:
    "The requested page or resource either could not be found, or you don't have permission to view it.",

  // about.tsx
  AboutUs: "About Us",

  // how-to-order.tsx
  HowToOrder: "How To Order",

  // privacy-policy.tsx
  PrivacyPolicy: "Privacy Policy",

  // products.tsx
  Products: "Products",

  // terms.tsx
  TermsAndConditions: "Terms & Conditions",

  // usa-made.tsx
  UsaMade: "USA Made",

  // virtual-sample.tsx
  VirtualSample: "Virtual Sample",
};

export const CrystallizeBaseURL =
  "https://api.crystallize.com/awesomepromos/catalogue";

export const CloudBaseURL =
  "https://us-central1-awesomepromos.cloudfunctions.net";

export const Algolia = {
  AppId: "D40R2811TE",
  IndexName: "AwesomePromos",
  ApiKey: "903d86fbb4c8526761d35b312db6a028",
};

export const InfoDialog = {
  SuccessTitle: "Request Sent",
  SuccessMessage:
    "We've received your request and will be in touch as soon as possible.",
  FailureTitle: "Request Not Sent",
  FailureMessage:
    "There was an issue sending your request. Please try again in a moment.",
};

export const Search = {
  PathObjName: "Search",
  SearchText: "Search...",
  ArrowUpKey: "ArrowUp",
  ArrowDownKey: "ArrowDown",
  EnterKey: "Enter",
  ProductsPath: "/products",
  InfoComponentName: "Information",
  PriceComponentName: "Price",
  SkuPropertyName: "SKU",
  SearchPath: "/search",
  AltSearchPath: "/search/",
  ShowingResultsText: "Showing results for:",
  QuickLinksText: "Quick Links",
  NoResultsText: "No results found. Please try again with a different search.",
};

export const LoadingSpinner = {
  LoadingText: "Loading Results",
};

export const GridItem = {
  AsLowAsText: " - As Low As: ",
  MoreInfoText: "More Info",
  ProductsPath: "/products",
  InfoComponentName: "Information",
  PriceComponentName: "Price",
};

export const Header = {
  HeaderText:
    "Welcome to the premier source of unique brand name promotional products.",
};

export const Navbar = {
  Links: [
    { Name: "Home", Path: "/" },
    { Name: "Our Products", Path: "/products" },
    { Name: "How to Order", Path: "/how-to-order" },
    { Name: "Virtual Sample", Path: "/virtual-sample" },
    { Name: "About Us", Path: "/about" },
  ],
  MouseEnterEvent: "mouseenter",
  MouseLeaveEvent: "mouseleave",
  OurProductsName: "Our Products",
  ProductsPanelId: "productsPanelId",
};

export const Product = {
  NoCustomImageText:
    "* We don't have an image for that combination. Please try another selection.",
};

export const InfoTabs = {
  DetailsText: "Details",
  ProductDescText: "Product Description",
  DecorationLocationText: "Decoration Location Image",
  AdditionalInfoText: "Additional Information",
  NoDecorLocImageText:
    "No decoration location image is available for this product.",
};

export const ProductDetails = {
  NotSpecifiedText: "Not Specified",
};

export const Options = {
  ChooseOptionText: "Choose an option...",
};

export const UsaMade = {
  UsaMadeText: "USA Made",
  DescriptionHtml:
    "<p>Brand name promotional products Made in the USA. American Pride. American Made.</p>",
};

export const Products = {
  UsaMadeText: "USA Made",
  OurProductsText: "Our Products",
  ProductsHeaderText:
    "Awesome Promos offers brand name products that can be decorated with a custom logo. " +
    "Check out these great brands that can provide quality and functional promotional products.",
  ExcludedFolders: ["Slideshow", "Articles"],
};

export const MobileNavbar = {
  MenuText: "Menu",
  HomeName: "Home",
  OurProductsName: "Our Products",
};

export const ProductsPanel = {
  ExcludedPaths: ["/articles", "/slideshow"],
  ProductsPanelId: "productsPanelId",
  ProductsPath: "/products",
  USAMadePath: "/products/usa-made",
  USAMadeText: "USA Made",
};

export const ProductGrid = {
  SortByName: "sortBy",
  SortDirectionName: "sortDirection",
  ResultsPerPageName: "resultsPerPage",
  CurrentPageName: "currentPage",
  AscendingValue: "ascending",
  NameValue: "name",
};

export const Pagination = {
  CurrentPageName: "currentPage",
};

export const SortBy = {
  SortByName: "sortBy",
  SortByText: "Sort By",
  NameValue: "name",
  PriceValue: "price",
  PopularityValue: "popularity",
  NameText: "Name",
  PriceText: "Price",
  PopularityText: "Popularity",
  SortDirName: "sortDirection",
  TooltipAscText: "Sort Ascending Direction",
  TooltipDescText: "Sort Descending Direction",
  AscendingValue: "ascending",
  DescendingValue: "descending",
};

export const ResultsPerPage = {
  ResultsPerPageText: "Results Per Page",
  ResultsPerPageName: "resultsPerPage",
};

export const Footer = {
  Links: [
    { Name: "Home", Path: "/" },
    { Name: "Our Products", Path: "/products" },
    { Name: "How to Order", Path: "/how-to-order" },
    { Name: "Virtual Sample", Path: "/virtual-sample" },
    { Name: "About Us", Path: "/about" },
    { Name: "Privacy Policy", Path: "/privacy-policy" },
    { Name: "Terms & Conditions", Path: "/terms" },
  ],
};

export const Logo = {
  Name: "BrandBox®",
  Path: "/",
  Image: "/awesomepromos-logo.png",
};

export const HomePage = {
  BannerText: "Our Most Popular Items",

  // promo.tsx
  LearnMore: "Learn More",
};

export const VirtualSamplePage = {
  // progress-stepper.tsx
  Steps: ["Select a brand", "Select a product", "Fill out request"],
};

/* Virtual Sample Page */
export type VirtualSampleFormState = {
  name: string;
  email: string;
  company: string;
  asiNumber: string;
  phoneNumber: string;
  designNotes: string;
  formIsValid: boolean;
  hasValidSelection: boolean;
};

/* Virtual Sample Page */
export type VirtualSampleDropdownState = {
  label: string;
  value: string;
  valueAsText: string;
  options: { id: string; name: string; defaultImages?: GQLImage[] }[];
  required?: boolean;
  disabled?: boolean;
};

export default GlobalConstants;
