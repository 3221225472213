import React from "react";
import { Navbar as Constants } from "../../../helpers/constants";
import { ButtonGroup, Button, makeStyles } from "@material-ui/core";
import { Link } from "gatsby";

const UseStyles = makeStyles({
  buttonGroup: {
    borderRadius: "0",
    backgroundColor: "#1b1b1b",
    width: "100%",

    "& Button": {
      color: "lightgrey",
      backgroundColor: "#1b1b1b",
      "&.MuiButton-root": { padding: "0" },

      "& a": {
        padding: "1rem",
        color: "inherit",
        textDecoration: "none",

        "&:hover": {
          textDecoration: "none",
        },
      },
      "&:hover": {
        backgroundColor: "#00958D",
        borderRadius: "0",
      },
    },
  },

  selected: {
    "& a": {
      backgroundColor: "#00958D !important",
      borderRadius: "0",
    },
  },

  lastButton: {
    borderRight: "1px solid lightgrey",
  },
});

type NavbarProps = {
  currentPage: string;
};

/*
 * currentPage -> The page being viewed
 */
const Navbar = (props: NavbarProps): JSX.Element => {
  const Classes = UseStyles();

  return (
    <React.Fragment>
      <ButtonGroup variant="contained" className={Classes.buttonGroup}>
        {Constants.Links.map((link, index) => (
          <Button
            key={index}
            name={Constants.OurProductsName}
            className={`
              ${props.currentPage === link.Path ? Classes.selected : null}
              ${
                index === Constants.Links.length - 1 ? Classes.lastButton : null
              }
            `}
          >
            <Link to={link.Path} id={link.Name}>
              {link.Name}
            </Link>
          </Button>
        ))}
      </ButtonGroup>
    </React.Fragment>
  );
};

export default Navbar;
