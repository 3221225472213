import React from "react";
import { Search as Constants } from "../../../../helpers/constants";
import { List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import { GQLProduct } from "../../../../types";

const UseStyles = makeStyles({
  list: {
    position: "absolute",
    right: "0rem",
    width: "15.65rem",
    zIndex: 1,
    backgroundColor: "white",
    padding: "0rem",
  },

  show: {
    display: "block",
  },

  hide: {
    display: "none",
  },

  listItem: {
    "&:hover": {
      backgroundColor: "white",
    },
  },

  listItemSelected: {
    backgroundColor: "#00958D",
    color: "white",

    "&:hover": {
      backgroundColor: "#00958D",
      color: "white",
    },
  },

  searchWrapper: {
    position: "relative",
  },
});

/* Event handler for clicking on an item in the search results
 * - productPath -> The path of the product that was selected
 */
const handleClick = (productPath) => {
  window.location.href = Constants.ProductsPath + productPath;
};

type SearchResultsProps = {
  state: {
    refs: React.RefObject<HTMLDivElement>[];
    focusedIndex: number;
    results: (GQLProduct & { popularity: string })[];
    visible: boolean;
  };
  onHover: (focusedIndex: number) => void;
};

/*
 * state:
 *  refs -> React references to results
 *  focusedIndex -> Index of the currently selected item
 *  results -> The results of the search
 *  visible -> Whether to show this component or not
 * onHover -> Event handler for hover state changes
 */
const SearchResults = (props: SearchResultsProps): JSX.Element => {
  const Classes = UseStyles();

  return (
    <div className={Classes.searchWrapper}>
      <List
        component="nav"
        className={
          props.state.visible
            ? `${Classes.list} ${Classes.show}`
            : `${Classes.list} ${Classes.hide}`
        }
      >
        {props.state.results?.map((node, index) => {
          const Selected = index === props.state.focusedIndex;

          return (
            index < 10 && (
              <ListItem
                button
                divider
                key={index}
                ref={props.state.refs[index]}
                onMouseEnter={() => props.onHover(index)}
                className={
                  Selected ? Classes.listItemSelected : Classes.listItem
                }
              >
                <ListItemText
                  primary={node.name}
                  onClick={() => handleClick(node.path)}
                />
              </ListItem>
            )
          );
        })}
      </List>
    </div>
  );
};

export default SearchResults;
