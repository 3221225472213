import { createMuiTheme } from "@material-ui/core";

const Theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00958D",
    },
    secondary: {
      main: "#D3D3D3",
    },
    background: {
      default: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
});

export default Theme;
