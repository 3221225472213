import React from "react";
import MenuIcon from "@material-ui/icons/Menu";
import NavbarLink from "./mobile-navbar/navbar-link";
import { Navbar, MobileNavbar as Constants } from "../../../helpers/constants";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  makeStyles,
} from "@material-ui/core";

const UseStyles = makeStyles((theme) => ({
  accordion: {
    color: "white",

    "& .MuiAccordionSummary-root": {
      backgroundColor: "#00958D",

      "& .MuiAccordionSummary-content": {
        margin: ".5rem 0",
      },
      "&.Mui-expanded": {
        minHeight: "0",
      },
    },
    "& .MuiIconButton-root": {
      color: "white",
    },
    "& .MuiAccordionDetails-root": {
      padding: "0",
    },
    "& .MuiGrid-root": {
      width: "100%",
    },

    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const MobileNavbar = (): JSX.Element => {
  const Classes = UseStyles();

  return (
    <Accordion square className={Classes.accordion}>
      <AccordionSummary expandIcon={<MenuIcon />}>
        <Typography variant="h6">{Constants.MenuText}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          {Navbar.Links.map(
            (link, index) =>
              link.Name !== Constants.HomeName && (
                <React.Fragment key={index}>
                  <NavbarLink link={link} />
                </React.Fragment>
              )
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default MobileNavbar;
